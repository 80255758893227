
import { Options, Vue } from "vue-class-component";

@Options({
  name: "Loisirs",
})
export default class Loisirs extends Vue {
  // ...
  created(): void {
    this.$store.commit("setCurrentPageTitle", "Loisirs");
  }
}
